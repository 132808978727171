import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Image = ({src}) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "${src}" }) {
  //       childImageSharp {
  //         fluid(maxWidth: ${maxWidth}) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)
  graphql`
    fragment squareImage on File {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `
  const data = useStaticQuery(graphql`
    query {
      img010: file(relativePath: { eq: "session-2/010.jpg" }) {...squareImage}
      img011: file(relativePath: { eq: "session-2/011.jpg" }) {...squareImage}
      img033: file(relativePath: { eq: "session-2/033.jpg" }) {...squareImage}
      guide134: file(relativePath: { eq: "session-3/guide134.jpg" }) {...squareImage}
      guide135: file(relativePath: { eq: "session-3/guide135.jpg" }) {...squareImage}
      i9p11: file(relativePath: { eq: "session-3/i9p11.jpg" }) {...squareImage}
    }
  `)

  return <Img fluid={data[src].childImageSharp.fluid} />
}
