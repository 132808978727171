import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from "gatsby-image"
import {Image} from '../utils'

export const Content = () => 
  <div>
    <h1>Session 3 (Feb 27<sup>th</sup>)</h1>

    <h2>Task #1: Speaking — from Guide</h2>

    <Image src="guide134" />
    <Image src="guide135" />

    <h2>Task #2: Reading</h2>
    <audio src="https://lyceum.aularon.com/audio/i9t1p2.m4a" controls />

    <Image src="i9p11" />
  </div>


const SecondPage = () => (
  <Layout>
    <SEO title="Session 2" />
    <Content />
  </Layout>
)



export default SecondPage
